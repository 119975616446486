import React, { FunctionComponent, ReactNode, useEffect } from 'react'
import {
  Box,
  GlobalStyles,
  IllustrationContainer,
} from '@mindfulchefuk/design-system'
import { OnboardingLayoutGrid } from '@mindfulchefuk/features/Onboarding/components/layout/OnboardingLayout.styles'
import MainLayout from '@mindfulchefuk/layouts/MainLayout'
import { OnboardingCartNavigationContainer } from '@mindfulchefuk/features/Cart/components/CartNavigation/OnboardingCartNavigationContainer'
import { useCartDrawer } from '@mindfulchefuk/features/Cart/useCartDrawer'
import { OnboardingCartDrawer } from '@mindfulchefuk/features/Cart/components/CartDrawer/OnboardingCartDrawer'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '@mindfulchefuk/types/store'
import { useGroupRecipesByQuantity } from '@mindfulchefuk/features/Onboarding/hooks/useGroupRecipesByQuantity'
import { CartItemType } from '@mindfulchefuk/features/Cart/interfaces'
import { useRecipeCartTotals } from '@mindfulchefuk/features/Onboarding/hooks/useRecipeCartTotals'
import { useChooseRecipesActions } from '@mindfulchefuk/features/Onboarding/hooks/useChooseRecipesActions'
import { BasketBanner } from '@mindfulchefuk/features/Onboarding/components/ChooseRecipes/BasketBanner/BasketBanner'

import { getMinimumMaximumRecipesForPortion } from '@mindfulchefuk/services/getMinimumMaximumRecipesForPortion'
import { NotificationSuccessVoucherApplied } from '@mindfulchefuk/features/Notifications/components/variants/NotificationSuccessVoucherApplied'
import { instrumentBasketViewEvent } from '@mindfulchefuk/utils/analytics/amplitude/instrument'

type TChooseRecipesLayout = {
  title?: string
  canonical?: string
  description?: string
}

export const ChooseRecipesLayout: FunctionComponent<
  TChooseRecipesLayout & { children: ReactNode }
> = ({ canonical, title, description, children }) => {
  const {
    items,
    portionCount,
    discountApplied,
    total,
    totalWithDiscount,
    isBasketLoading,
    shippingPrice,
  } = useSelector(
    (store: RootState) => ({
      items: store.basket.items,
      portionCount: store.basket.portionCount,
      discountApplied: store.basket.discountApplied,
      total: store.basket.total,
      totalWithDiscount: store.basket.totalWithDiscount,
      isBasketLoading: store.basket.isBasketLoading,
      shippingPrice: store.basket.recipeShippingPrice,
    }),
    shallowEqual
  )
  const { total: finalTotal, originalTotal } = useRecipeCartTotals()
  const recipesByQuantity = useGroupRecipesByQuantity(items)

  const {
    handleConfirmClick,
    handleRemoveAllRecipe,
    handleIncrementRecipeFromBasket,
    handleDecrementRecipeFromBasket,
    handleConfirmClickFromTheBasket,
  } = useChooseRecipesActions()

  const { minimumRecipes } = getMinimumMaximumRecipesForPortion({
    portionCount,
    journey: 'onboarding',
  })
  const minRecipesNotReached = items.length < minimumRecipes

  const { isDrawerOpen, toggleDrawerOpen } = useCartDrawer()

  useEffect(() => {
    if (isDrawerOpen) {
      instrumentBasketViewEvent({
        cartId: '',
        cartSize: items.length,
        productsLength: 0,
        recipesLength: items.length,
        totalRecipeAmount: finalTotal,
        totalReadyToGoAmount: 0,
        totalBasketAmount: finalTotal,
        deliveryAmount: parseFloat(shippingPrice),
        pageSource: 'choose recipe page',
        userIsLoggedIn: false,
      })
    }
  }, [isDrawerOpen, items.length, shippingPrice, finalTotal])

  return (
    <MainLayout
      hideFooter
      hideNav
      title={title}
      description={description}
      canonical={canonical}
      isOnboarding
      customNav={
        <Box ml="auto">
          <OnboardingCartNavigationContainer
            toggleDrawerOpen={toggleDrawerOpen}
          />
        </Box>
      }
    >
      <NotificationSuccessVoucherApplied />
      <GlobalStyles />
      <Box overflow="hidden">
        <IllustrationContainer illustrations="07" as="main">
          <OnboardingLayoutGrid>{children}</OnboardingLayoutGrid>
        </IllustrationContainer>
      </Box>

      <BasketBanner
        numRecipes={items.length}
        price={total}
        portionCount={portionCount}
        discountApplied={discountApplied}
        totalWithDiscount={totalWithDiscount}
        items={items}
        isBasketLoading={isBasketLoading}
        onPressContinue={handleConfirmClick}
        toggleDrawerOpen={toggleDrawerOpen}
      />

      <OnboardingCartDrawer
        isDrawerOpen={isDrawerOpen}
        toggleDrawerOpen={toggleDrawerOpen}
        onPressContinue={
          minRecipesNotReached
            ? toggleDrawerOpen
            : handleConfirmClickFromTheBasket
        }
        total={finalTotal}
        originalTotal={originalTotal}
        type={CartItemType.RECIPE}
        recipes={recipesByQuantity}
        addRecipe={handleIncrementRecipeFromBasket}
        removeRecipe={handleDecrementRecipeFromBasket}
        removeAllRecipe={handleRemoveAllRecipe}
        orderShippingAmount={parseFloat(shippingPrice)}
      />
    </MainLayout>
  )
}
