import React, { FunctionComponent, useEffect } from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'

import { RootState } from '@mindfulchefuk/types/store'

import { CartNavigation } from '@mindfulchefuk/features/Cart/components/CartNavigation/CartNavigation'
import { useGroupRecipesByQuantity } from '@mindfulchefuk/features/Onboarding/hooks/useGroupRecipesByQuantity'
import { clearLastAddedRecipe } from '@mindfulchefuk/actions/basketActions'

import { getMinimumMaximumRecipesForPortion } from '@mindfulchefuk/services/getMinimumMaximumRecipesForPortion'

type Props = {
  toggleDrawerOpen: () => void
}

export const OnboardingCartNavigationContainer: FunctionComponent<Props> = ({
  toggleDrawerOpen,
}) => {
  const { items, portionCount, totalRecipes, lastAddedRecipeId } = useSelector(
    (store: RootState) => ({
      items: store.basket.items,
      portionCount: store.basket.portionCount,
      totalRecipes: store.basket.items.length,
      lastAddedRecipeId: store.basket.lastAddedRecipeId,
    }),
    shallowEqual
  )
  const dispatch = useDispatch()
  const recipes = useGroupRecipesByQuantity(items)

  const lastAddedRecipe = recipes.find(({ id }) => lastAddedRecipeId === id)

  useEffect(() => {
    return () => {
      dispatch(clearLastAddedRecipe())
    }
  }, [dispatch])

  const { maximumRecipes } = getMinimumMaximumRecipesForPortion({
    portionCount,
    journey: 'onboarding',
  })

  return (
    <CartNavigation
      onClick={toggleDrawerOpen}
      recipes={recipes}
      totalRecipes={totalRecipes}
      portionCountPerMeal={portionCount}
      maxRecipes={maximumRecipes}
      showSubText={false}
      lastAddedRecipe={lastAddedRecipe}
    />
  )
}
